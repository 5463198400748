import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.users;

export const getAllUsers = createSelector(getState, (users) => users.users);

export const getRecruiters = createSelector(getState, (users) => users.usersRecruiters);

export const getTotalLength = createSelector(getState, (users) => users.totalLength);

export const getError = createSelector(getState, (users) => users.error);

export const getChangeUserError = createSelector(getState, (users) => users.errorChangeUser);

export const getSuccessMessage = createSelector(getState, (users) => users.successMessage);

export const getActiveUserId = createSelector(getState, (users) => users.activeUser);

export const getClientUsers = createSelector(getState, (users) => {
  const result = users.users.filter((user) => user.role === 'ROLE_CLIENT' || user.role === 'ROLE_CLIENT_EDITOR');
  return result;
});

export const getFirstClient = createSelector(getState, (users) => {
  const result = users.users.filter((user) => user.role === 'ROLE_CLIENT' || user.role === 'ROLE_CLIENT_EDITOR');

  return result[0].id;
});
export const getUsersStatistics = createSelector(getState, (users) => users.usersStatistics);
export const getUsersBirthdays = createSelector(getState, (users) => users.usersBirthdays);
export const getSelectedUser = createSelector(getState, (users) => users.selectedUser);
export const getUsersByVacancy = createSelector(getState, (users) => users.vacancyUsers);
export const getWorkAnniversary = createSelector(getState, (users) => users.workAnniversary);
export const getLoading = createSelector(getState, (users) => users.loading);
export const getIsUserExist = createSelector(getState, (users) => users.isUserExist);
