import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Popover, Typography } from '@mui/material';
import PopoverContent from 'components/users-section/user-profiles-popover';
import { useState } from 'react';

import { useSelector } from 'react-redux';

import ProfilesIcons from 'shared/profiles-icons';
import { LOADING } from '../../../assets/loading';
import { getCurrentUser } from '../../../redux/selectors/auth';
import { getSelectedTeam } from '../../../redux/selectors/teams';
import { getLoading } from '../../../redux/selectors/users';
import { SkeletonWrapper } from '../../../shared/skeleton/SkeletonWrapper';

import useStyles from './style';

type propTypes = {
  isSidebarOpen: boolean;
  mobile: boolean;
};

const ProfilesNavigation = ({ isSidebarOpen, mobile }: propTypes) => {
  const { classes } = useStyles({ isSidebarOpen, mobile });

  const user = useSelector(getCurrentUser);
  const userLoadingStatus = useSelector(getLoading);
  const teamName = useSelector(getSelectedTeam)?.name;
  const isUserLoading = !user && !teamName && userLoadingStatus !== LOADING.SUCCESS;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div aria-describedby={id} className={classes.profilesContainer} onClick={handleClick}>
        <SkeletonWrapper
          isLoading={isUserLoading}
          sx={{ backgroundColor: '#F3F5F7' }}
          variant={'circular'}
          width={36}
          height={36}
        >
          <ProfilesIcons />
        </SkeletonWrapper>
        {isSidebarOpen && (
          <>
            <div className={classes.profilesNames}>
              {teamName ? (
                <>
                  <SkeletonWrapper isLoading={isUserLoading} sx={{ backgroundColor: '#F3F5F7' }}>
                    <Typography>{teamName}</Typography>
                  </SkeletonWrapper>
                  <SkeletonWrapper isLoading={isUserLoading} sx={{ backgroundColor: '#F3F5F7' }}>
                    <Typography
                      className={classes.userName}
                    >{`${user?.firstNameLatin} ${user?.lastNameLatin}`}</Typography>
                  </SkeletonWrapper>
                </>
              ) : (
                <SkeletonWrapper isLoading={isUserLoading} sx={{ backgroundColor: '#F3F5F7' }}>
                  <Typography>{`${user?.firstNameLatin} ${user?.lastNameLatin}`}</Typography>
                </SkeletonWrapper>
              )}
            </div>
            <ArrowDropDownIcon style={{ color: '#FAFAFA', width: '24px', height: '24px' }} />
          </>
        )}
      </div>
      <Popover
        id={id}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={0}
        slotProps={{
          paper: {
            style: {
              boxShadow: '2px 4px 16px 0px rgba(0, 0, 0, 0.05)',
              borderRadius: 0,
              marginLeft: '10px',
              marginTop: '-10px',
            },
          },
        }}
      >
        <PopoverContent closePopover={handleClose} />
      </Popover>
    </div>
  );
};

export default ProfilesNavigation;
