import { RoutesConstants } from 'constants/routesConstants';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import Loading from 'shared/loading';
import { tokenStorage } from '../../helpers/storageFunctions';

interface IPrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  rolePermission: boolean;
  redirectPath?: string;
  isLoading?: boolean;
}

function PrivateRoute({ component: Component, rolePermission, redirectPath, isLoading, ...rest }: IPrivateRouteProps) {
  const isUserLoggedIn = Boolean(tokenStorage().getAccessToken());

  if (isUserLoggedIn && rest.location?.pathname === '/') {
    return <Redirect to={RoutesConstants.HOMEPAGE} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!isUserLoggedIn || rolePermission === false) {
    return <Redirect to={redirectPath || RoutesConstants.HOMEPAGE} />;
  }

  return <Route {...rest} component={Component} />;
}

export default PrivateRoute;
