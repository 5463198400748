import { takeLatest } from 'redux-saga/effects';
import { HIRE_CANDIDATE_SUCCESS, REJECT_CANDIDATE_SUCCESS, UPDATE_CANDIDATE_SUCCESS } from 'redux/actions/candidates';
import { onHireCandidate } from './onHireCandidate';
import { onRejectCandidate } from './onRejectCandidate';
import { onUpdateCandidate } from './onUpdateCandidate';

export default function* fn() {
  // yield takeLatest(CHANGE_CANDIDATE_STATUS_SUCCESS.type, onChangeCandidateStatus);
  yield takeLatest(UPDATE_CANDIDATE_SUCCESS.type, onUpdateCandidate);
  yield takeLatest(REJECT_CANDIDATE_SUCCESS.type, onRejectCandidate);
  yield takeLatest(HIRE_CANDIDATE_SUCCESS.type, onHireCandidate);
}
