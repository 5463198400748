import { Skeleton, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

interface SkeletonWrapperProps {
  isLoading: boolean;
  width?: number | string;
  height?: number | string;
  variant?: 'text' | 'rectangular' | 'circular' | 'rounded';
  sx: SxProps<Theme> | undefined;
  children: ReactNode;
}

export const SkeletonWrapper = ({
  isLoading,
  width = 100,
  height = 24,
  variant = 'text',
  sx,
  children,
}: SkeletonWrapperProps) => {
  if (isLoading) {
    return <Skeleton variant={variant} width={width} height={height} sx={sx} />;
  }

  return <>{children}</>;
};
