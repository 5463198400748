import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { RoutesConstants } from 'constants/routesConstants';
import { lazy, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.scss';
import AuthorizationHeader from './components/authorization-section/header';
import ErrorBoundary from './components/error-boundary';
import Sidebar from './components/navigations-section/sidebar';
import NotificationProvider from './components/notification/notificationContext';
import PrivateRoute from './components/private-route';
import PublicRoute from './components/public-route';
import { localeObj, localeObjType } from './helpers/dates';
import { tokenStorage } from './helpers/storageFunctions';
import initLocalization from './i18n';
import { fetchCurrentUser } from './redux/actions/auth';

import { getCurrentUser } from './redux/selectors/auth';

import { RolePermissions } from 'helpers/RolePermissions';
import { getSelectedTeam } from 'redux/selectors/teams';
import Loading from './shared/loading';
import theme from './theme/theme';

initLocalization();

const App = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const activeTeam = useSelector(getSelectedTeam);
  const isUserLogIn = Boolean(tokenStorage().getAccessToken());

  const currentEmployeeInTeam = activeTeam?.employees?.find((employee) => employee?._id?._id === currentUser?._id);
  const currentUserRole = currentEmployeeInTeam?.role;

  useEffect(() => {
    if (isUserLogIn) {
      dispatch(fetchCurrentUser());
    }
  }, [dispatch, isUserLogIn]);

  const isLoading = isUserLogIn && (!currentUser || !activeTeam);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <ErrorBoundary>
            <div className='App'>
              <Suspense fallback={<Loading />}>
                <NotificationProvider>
                  {!currentUser && !isUserLogIn ? (
                    <AuthorizationHeader>
                      <Switch>
                        <PublicRoute path='/' exact restricted component={lazy(() => import('./containers/signin'))} />
                        <PublicRoute
                          path={['/signup', '/signup/:token/:teamName/:userEmail']}
                          exact
                          restricted
                          component={lazy(() => import('./containers/signup'))}
                        />
                        <PublicRoute
                          path='/resetpassword'
                          exact
                          restricted
                          component={lazy(() => import('./containers/reset-password'))}
                        />
                        <PublicRoute
                          path='/reset-password/:token'
                          exact
                          restricted
                          component={lazy(() => import('./containers/reset-password-by-email'))}
                        />
                        <PublicRoute path='*' exact restricted component={lazy(() => import('./containers/signin'))} />
                      </Switch>
                    </AuthorizationHeader>
                  ) : (
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={localeObj[i18n.language as keyof localeObjType]}
                    >
                      <Sidebar>
                        <main className='content-container'>
                          <Switch>
                            <PrivateRoute
                              exact
                              path='/team/create'
                              component={lazy(() => import('./containers/team-creation'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/profile/:type'
                              component={lazy(() => import('./containers/user-overview'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/profiles-navigation'
                              component={lazy(() => import('./containers/user-profiles-mobile'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/user/:id/:type'
                              component={lazy(() => import('./containers/user-overview'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/vacancies'
                              component={lazy(() => import('./containers/vacancies'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path={['/vacancies/create', '/vacancy/:id']}
                              component={lazy(() => import('./containers/vacancy-creation'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/vacancy/:id/:type'
                              component={lazy(() => import('./containers/vacancy-overview'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/candidates'
                              component={() => <Redirect to={RoutesConstants.HOMEPAGE} />}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path={['/candidates/create', '/candidate/:id']}
                              component={lazy(() => import('./containers/candidate-creation'))}
                              rolePermission={RolePermissions.canCreateAndEditCandidate(currentUserRole!)}
                              redirectPath={'/candidates/list'}
                              isLoading={isLoading}
                            />
                            <PrivateRoute
                              exact
                              path={['/', '/candidates/:type']}
                              component={lazy(() => import('./containers/candidates-overview'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/candidates/:id/:type'
                              component={lazy(() => import('./containers/candidate-profile'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/requests/:type'
                              component={lazy(() => import('./containers/vacancies'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/statistics'
                              component={lazy(() => import('./containers/hr-statistics'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/team/profile'
                              component={lazy(() => import('./containers/team-profile'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/user/:id/details'
                              component={lazy(() => import('./containers/user-overview'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/team/rename'
                              component={lazy(() => import('./containers/rename-team-mobile'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/clients'
                              component={lazy(() => import('./containers/clients'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/clients/:id'
                              component={lazy(() => import('./containers/client-profile/ClientProfile'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/clients/:id/vacancies'
                              component={lazy(() => import('./containers/client-profile/ClientProfile'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/clients/:id/decision-makers'
                              component={lazy(() => import('./containers/client-profile/ClientProfile'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/create-client'
                              component={lazy(() => import('./containers/client-creation'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/clients/:id/edit'
                              component={lazy(() => import('./containers/client-creation'))}
                              rolePermission={true}
                            />
                            <PrivateRoute
                              exact
                              path='/reminders'
                              component={lazy(() => import('./containers/reminders/reminders-overview'))}
                              rolePermission={true}
                            />
                            <Route path='*' component={lazy(() => import('./components/not-found'))} />
                          </Switch>
                        </main>
                      </Sidebar>
                    </LocalizationProvider>
                  )}
                </NotificationProvider>
              </Suspense>
            </div>
          </ErrorBoundary>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
